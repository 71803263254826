// vendors
import React from 'react';

const IconWatch = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.88 34.34' {...props}>
    <g
      style={{ fill: 'none', stroke: 'currentColor' }}
      strokeLinejoin='round'
      strokeWidth='1.7'
    >
      <path d='M13.94,30.37A13.2,13.2,0,1,0,.85,17.17,13.15,13.15,0,0,0,13.94,30.37Z' />
      <line x1='5.66' y1='7.08' x2='5.66' />
      <line x1='22.22' y1='7.08' x2='22.22' />
      <line x1='5.66' y1='34.34' x2='5.66' y2='27.38' />
      <line x1='22.22' y1='34.34' x2='22.22' y2='27.38' />
      <polyline points='20.29 21.19 13.78 18.31 13.78 8.05' />
    </g>
  </svg>
);

export default IconWatch;
