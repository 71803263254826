// vendors
import React from 'react';

const IconPointer = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.04 34.04' {...props}>
    <g style={{ fill: 'none', stroke: 'currentColor' }} strokeLinejoin='round'>
      <g strokeWidth='1.7'>
        <path d='M11,28.19S21.19,18.72,21.19,11A10.17,10.17,0,0,0,.85,11C.85,18.72,11,28.19,11,28.19Z' />
        <line x1='2.92' y1='33.19' x2='19.12' y2='33.19' />
      </g>
      <circle strokeWidth='1.36' cx='11.02' cy='10.68' r='3.27' />
    </g>
  </svg>
);

export default IconPointer;
