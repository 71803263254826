// vendors
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPicture = styled.picture`
  display: block;

  background-size: cover;

  transition-property: opacity;

  pointer-events: none;
  will-change: opacity;

  img,
  source {
    max-height: 100%;
    object-fit: cover;

    pointer-events: none;
    will-change: opacity;
  }
`;

const PictureSlide = ({
  img,
  animating,
  active,
  style,
  wrapperRef,
  ...rest
}) => {
  const pictureRef = useRef(null);
  const [visibled, setVisibled] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const sizes = `(max-width: 608px) ${
    224 * img.aspectRatio
  }px, (max-width: 1560px) ${(576 / 1560) * img.aspectRatio * 100}vw, 800px`;

  useEffect(() => {
    let intersectionObserver;
    if (wrapperRef !== null && pictureRef !== null) {
      intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio <= 0) return;

          setVisibled(true);
        },
        {
          root: wrapperRef.current,
        }
      );

      intersectionObserver.observe(pictureRef.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [wrapperRef, pictureRef]);

  const handleLoad = () => setLoaded(true);

  return (
    <StyledPicture
      ref={pictureRef}
      style={{
        transitionDuration: !animating ? '0ms' : '400ms',
        opacity: active && loaded ? 1 : 0.3,
        mixBlendMode: active && loaded ? '' : 'luminosity',
        backgroundImage: `url(${img.base64})`,
        ...style,
      }}
      {...rest}
    >
      {visibled && (
        <>
          <source
            onLoad={handleLoad}
            style={{ opacity: loaded ? 1 : 0 }}
            sizes={sizes}
            srcSet={img.srcSetWebp}
            type='image/webp'
          />

          <img
            onLoad={handleLoad}
            style={{ opacity: loaded ? 1 : 0 }}
            sizes={sizes}
            src={img.src}
            alt={img.alt}
            srcSet={img.srcSet}
          />
        </>
      )}
    </StyledPicture>
  );
};

PictureSlide.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
    aspectRatio: PropTypes.string.isRequired,
    base64: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  animating: PropTypes.bool,
  active: PropTypes.bool,
  style: PropTypes.shape({}),
  wrapperRef: PropTypes.shape({
    current: PropTypes.object,
  }),
};

PictureSlide.defaultProps = {
  animating: false,
  active: false,
  style: {},
  wrapperRef: null,
};

export default PictureSlide;
