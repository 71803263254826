// vendors
import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { greaterThan, lessThan } from '../../../utils/mediaQuery';
import colors from '../../../styles/colors';

const breakpoint = 832;

export const linkAlt = css`
  && {
    color: ${colors.azure};
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &&:hover,
  &&:focus {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 56px;
  justify-content: center;

  margin-top: ${em(96)};

  ${greaterThan(breakpoint)} {
    grid-gap: 20px 40px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }
`;

export const MapContainer = styled.div`
  grid-row: 1 / span 5;
  grid-column: 2 / span 1;

  ${lessThan(breakpoint)} {
    display: none;
  }
`;

export const InfoLine = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  text-align: center;

  ${greaterThan(breakpoint)} {
    flex-flow: row;

    text-align: left;
  }

  p,
  ul {
    margin: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
`;

export const InfoLineIcon = styled.div`
  flex-shrink: 0;
  width: ${rem(32)};

  ${lessThan(breakpoint)} {
    margin-bottom: ${rem(16)};
  }

  ${greaterThan(breakpoint)} {
    margin-right: ${rem(28)};
  }

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

export const InfoLineContent = styled.div`
  flex-grow: 999;
`;

export const CTASeeService = styled.div`
  ${lessThan(breakpoint)} {
    margin: auto;
  }

  ${greaterThan(breakpoint)} {
    align-self: end;
    padding-top: ${rem(16)};
  }
`;
