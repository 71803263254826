import localizeDay from './localizeDay';

const isSequencial = (array) =>
  array.every(
    (num, index, src) => index === src.length - 1 || num < src[index + 1]
  );

const dayToConditionalString = (array) => {
  let result;

  if (array.length === 1) {
    result = localizeDay(array[0]);
  }

  if (array.length === 2) {
    result = array.map((day) => localizeDay(day)).join(' et ');
  }

  if (isSequencial(array)) {
    result = [array.slice(0, 1)[0], array.slice(-1)[0]]
      .map((day) => localizeDay(day))
      .join(' au ');
  }

  return result;
};

export default dayToConditionalString;
