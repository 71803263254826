import styled from 'styled-components';
import { rem } from 'polished';
import { lessThan, greaterThan } from '../../utils/mediaQuery';
import { h1Style } from '../../styles/global';
import { fontColors } from '../../styles/typography';
import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

const breakpoint = 832;

export const CTATextButton = styled.span``;

export const CTATextButtonHiddenLessThenDesktop = styled.span``;

export const CTAWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${lessThan(breakpoint)} {
    button,
    a {
      min-width: 0;
      padding: 16px;

      border: 0;
    }

    a span,
    button span {
      margin: 0 !important;
    }

    ${CTATextButton} {
      font-size: 0;
    }
  }

  ${lessThan(1024)} {
    ${CTATextButtonHiddenLessThenDesktop} {
      font-size: 0;
    }
  }

  ${greaterThan(breakpoint)} {
    grid-gap: 40px;
  }
`;

export const GalleryTitle = styled.h2`
  ${h1Style};

  margin: auto;
  margin-top: 0;
  padding: 0;

  color: ${fontColors.body};

  text-align: center;

  ${responsiveStyle('marginBottom', [80, 104], breakpoints.spacings)};
  ${responsiveStyle('paddingLeft', [28, 40, 80], breakpoints.spacings)};
  ${responsiveStyle('paddingRight', [28, 40, 80], breakpoints.spacings)};
`;

export const ContactUsContainer = styled.section`
  background-color: ${colors.white};
`;

export const ContactUsWrapper = styled.div`
  /* padding: ${rem(144)} 0 ${rem(168)}; */
  padding: 0;

  ${responsiveStyle('paddingTop', [96, 144], breakpoints.spacings)};
  ${responsiveStyle('paddingBottom', [96, 168], breakpoints.spacings)};

  text-align: center;
`;

export const ContactUsText = styled.p`
  max-width: ${rem(1152)};
  margin: ${rem(32)} auto;
  padding: 0 ${rem(28)};

  ${responsiveStyle('marginBottom', [56, 96], breakpoints.spacings)};
  ${responsiveStyle('fontSize', [20, 30], breakpoints.fonts)};

  ${greaterThan(breakpoints[2])} {
    padding: 0 ${rem(40)};
  }
`;

export const ContactUsImg = styled.img`
  width: ${(173 / 320) * 100}vw;
  max-width: 240px;

  margin: auto;
`;

export const CarouselSection = styled.section`
  position: relative;

  z-index: 1;
  ${responsiveStyle('marginTop', [96, 168], breakpoints.spacings)};
  ${responsiveStyle('marginBottom', [-168, -256], breakpoints.spacings)};

  & + * {
    ${responsiveStyle('paddingTop', [168, 256], breakpoints.spacings)};
  }
`;

export const Title = styled.h1`
  margin: 0;

  font-weight: 400;
  font-size: inherit;
`;
