/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import SheetLayout from '../../components/SheetLayout';
import BlockContent from '../../components/BlockContent';
import ContactSection from './ContactSection/ContactSection';
import Button from '../../components/Button';
import {
  CTAWrapper,
  CTATextButton,
  GalleryTitle,
  ContactUsContainer,
  ContactUsText,
  ContactUsImg,
  CarouselSection,
  ContactUsWrapper,
  Title,
  CTATextButtonHiddenLessThenDesktop,
} from './CliniqueTemplate.styles';
import IconEmail from '../../images/IconEmail';
import IconCalendar from '../../images/IconCalendar';
import contactUsVector from '../../images/iconBulle.svg';
import Carousel from '../../components/Carousel';
import { useContactForm } from '../../contexts/contactFormContext';
import { greaterThanCondition } from '../../utils/mediaQuery';
import colors from '../../styles/colors';
import SEO from '../../components/SEO';

const CliniqueTemplate = ({ data }) => {
  const { clinique } = data;

  const { open } = useContactForm();

  const handleOpenContactForm = () => {
    open({ clinique: clinique.name });
  };

  const contactSectionData = {
    address: clinique.address,
    email: clinique.email,
    phoneNumbers: clinique.phoneNumbers,
    openingHours: clinique.openingHours,
    documents: clinique.documents,
  };

  const galleryImages = clinique.gallery.map((image) => ({
    alt: image.alt,
    ...image.asset.fluid,
  }));

  const CTASection = (
    <CTAWrapper>
      <Button
        onClick={handleOpenContactForm}
        outlined
        renderIcon={<IconEmail />}
      >
        <CTATextButton>
          <CTATextButtonHiddenLessThenDesktop>
            Nous écrire un{' '}
          </CTATextButtonHiddenLessThenDesktop>
          courriel
        </CTATextButton>
      </Button>

      <Button
        primary
        outlined
        onClick={handleOpenContactForm}
        renderIcon={<IconCalendar />}
      >
        <CTATextButton>
          <CTATextButtonHiddenLessThenDesktop>
            Prendre un{' '}
          </CTATextButtonHiddenLessThenDesktop>
          rendez-vous
        </CTATextButton>
      </Button>
    </CTAWrapper>
  );

  const Titleheader = <Title>{clinique.name}</Title>;

  return (
    <Layout backgroundColor={colors.veryLightPink}>
      <SEO
        title={clinique.seo.seo_title}
        description={clinique.seo.meta_description}
      />

      <article>
        <SheetLayout renderHeader={Titleheader} actionSection={CTASection}>
          <BlockContent
            blocks={clinique._rawDesription}
            imageOptions={{
              maxWidth: 960,
              sizes: `${greaterThanCondition(768)} ${
                (832 / 992) * 100
              }vw, ${greaterThanCondition(992)} ${
                (960 / 1200) * 100
              }vw, ${greaterThanCondition(1200)} 960px, ${(721 / 768) * 100}vw`,
            }}
          />

          <ContactSection data={contactSectionData} />
        </SheetLayout>

        <CarouselSection>
          <GalleryTitle>
            La clinique de {clinique.address.city}&nbsp;en&nbsp;images
          </GalleryTitle>

          <Carousel images={galleryImages} />
        </CarouselSection>

        <ContactUsContainer>
          <ContactUsWrapper>
            <ContactUsImg src={contactUsVector} alt='' role='presentation' />

            <ContactUsText>
              Vous souhaitez obtenir plus d’information au sujet de la clinique
              Maxillo Québec de {clinique.address.city} ou rencontrer l’un de
              nos chirurgiens spécialistes?
            </ContactUsText>

            <Button onClick={handleOpenContactForm}>
              Nous écrire un courriel
            </Button>
          </ContactUsWrapper>
        </ContactUsContainer>
      </article>
    </Layout>
  );
};

CliniqueTemplate.propTypes = {
  data: PropTypes.shape({
    clinique: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string.isRequired,
      }).isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      phoneNumbers: PropTypes.arrayOf().isRequired,
      openingHours: PropTypes.shape().isRequired,
      documents: PropTypes.arrayOf().isRequired,
      _rawDesription: PropTypes.arrayOf().isRequired,
      gallery: PropTypes.arrayOf().isRequired,
      seo: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        meta_description: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CliniqueTemplate;

export const query = graphql`
  query($id: String!) {
    clinique: sanityLocation(id: { eq: $id }) {
      _rawDesription(resolveReferences: { maxDepth: 10 })
      email
      name
      address {
        coordonate {
          lat
          lng
        }
        ...AddressBlockData
      }
      openingHours {
        openingHourLines {
          day
          hours {
            fromTime {
              hour
              minute
            }
            toTime {
              hour
              minute
            }
            note
          }
        }
      }
      phoneNumbers {
        number
        post
        type
      }
      documents {
        label
        asset {
          url
          extension
        }
      }
      seo {
        meta_description
        seo_title
      }
      gallery {
        caption
        alt
        asset {
          id
          fluid(maxHeight: 576) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  }
`;
