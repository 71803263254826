// vendors
import React from 'react';

const IconPhone = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.49 34.04' {...props}>
    <g
      style={{ fill: 'none', stroke: 'currentColor' }}
      strokeLinejoin='round'
      strokeWidth='1.7'
    >
      <line x1='0.39' y1='26.23' x2='19.1' y2='26.23' />
      <rect x='0.85' y='0.85' width='17.79' height='32.34' rx='3.6' />
      <path
        style={{ fill: 'currentColor' }}
        d='M9.75,30.74a1,1,0,1,0-1.05-1,1,1,0,0,0,1.05,1'
      />
    </g>
  </svg>
);

export default IconPhone;
