// vendors
import styled from 'styled-components';
import { rem } from 'polished';
import colors from '../../styles/colors';

export const Container = styled.div`
  max-width: 1560px;
  margin: auto;
`;

export const Wrapper = styled.div`
  position: relative;

  width: 100%;
  height: ${(576 / 1560) * 100}vw;
  min-height: 224px;
  max-height: 576px;
  overflow: hidden;
`;

export const SlideList = styled.div`
  display: flex;
  height: 100%;

  transition-property: transform;

  pointer-events: none;
  will-change: transform;
`;

export const SlideContainer = styled.div`
  background-color: black;
`;

export const Pagination = styled.p`
  max-width: 800px;
  margin: ${rem(32)} auto;
  padding: ${rem(32)} ${rem(12)} 0;

  text-align: center;

  border-top: 3px solid ${colors.azure};
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - ${((800 + 50 + 50 + 40 + 40) / 1560 / 2) * 100}%);

  display: flex;
  justify-content: space-between;

  width: ${((800 + 50 + 50 + 40 + 40) / 1560) * 100}%;
`;

export const NavigationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  color: ${colors.azure};

  background: none;
  border: 2px solid currentColor;
  border-radius: 25px;

  transform: rotateZ(${({ back }) => back && '180deg'});

  cursor: pointer;

  appearance: none;

  svg {
    height: 50%;

    transform: translateX(15%);
  }
`;
