// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { rem } from 'polished';
import {
  Container,
  CTASeeService,
  InfoLine,
  InfoLineIcon,
  InfoLineContent,
  MapContainer,
  linkAlt,
} from './ContactSection.styles';
import Button from '../../../components/Button';

import AddressBlock from '../../../components/AddressBlock/AddressBlock';
import PhoneBlock from '../../../components/PhoneBlock';
import IconWatch from '../../../images/IconWatch';
import IconPhone from '../../../images/IconPhone';
import IconPointer from '../../../images/IconPointer';
import IconEmail from '../../../images/IconEmail';
import dayToConditionalString from '../../../utils/dayToConditionalString';
import Map from '../../../components/Map';
import colors from '../../../styles/colors';

const openingScheduleRow = (row) => {
  const formatedHours = row.hours.map((hour) => {
    let result = '';

    result += `${hour.fromTime.hour} h`;

    if (Number(hour.fromTime.minute) > 0) {
      result += String(` ${hour.fromTime.minute}`);
    }

    result += ` à ${hour.toTime.hour} h`;

    if (Number(hour.toTime.minute) > 0) {
      result += String(` ${hour.toTime.minute}`);
    }

    return result;
  });

  return (
    <p itemProp='openingHours'>
      {dayToConditionalString(row.day)}
      <br />
      {formatedHours}
    </p>
  );
};

const ContactSection = ({ data }) => {
  const mapCoordonates = [
    {
      position: {
        lat: data.address.coordonate.lat,
        lng: data.address.coordonate.lng,
      },
    },
  ];

  return (
    <Container>
      <InfoLine>
        <InfoLineIcon>
          <IconPointer
            css={css`
              width: 29px;
            `}
          />
        </InfoLineIcon>

        <InfoLineContent>
          <AddressBlock data={data.address} />
        </InfoLineContent>
      </InfoLine>

      <InfoLine>
        <InfoLineIcon>
          <IconPhone
            css={css`
              width: 26px;
            `}
          />
        </InfoLineIcon>

        <InfoLineContent>
          <PhoneBlock data={data.phoneNumbers} />
        </InfoLineContent>
      </InfoLine>

      <InfoLine>
        <InfoLineIcon>
          <IconEmail
            css={css`
              width: 35px;
            `}
          />
        </InfoLineIcon>

        <InfoLineContent>
          <p>
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </p>
        </InfoLineContent>
      </InfoLine>

      <InfoLine>
        <InfoLineIcon>
          <IconWatch
            css={css`
              width: 38px;
            `}
          />
        </InfoLineIcon>

        <InfoLineContent>
          {data.openingHours.openingHourLines.map(openingScheduleRow)}
        </InfoLineContent>
      </InfoLine>

      {data.documents?.length > 0 &&
        data.documents.map((document) => (
          <InfoLine>
            <InfoLineContent
              css={`
                margin-top: ${rem(28)};
              `}
            >
              <p>
                <span
                  css={`
                    background-color: ${colors.azure};
                    border-radius: 2px;
                    margin-right: 10px;
                  `}
                >
                  <span
                    css={`
                      position: relative;
                      text-transform: uppercase;
                      padding: 3px;
                      color: ${colors.white};
                      font-size: 0.875em;
                    `}
                  >
                    {document.asset?.extension}
                  </span>
                </span>

                <a
                  href={document.asset?.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  css={linkAlt}
                >
                  {document.label}
                </a>
              </p>
            </InfoLineContent>
          </InfoLine>
        ))}

      <CTASeeService>
        <Button to='/services' outlined primary>
          Voir les services offerts
        </Button>
      </CTASeeService>

      <MapContainer>
        <Map
          markers={mapCoordonates}
          options={{ center: { ...mapCoordonates[0].position } }}
        />
      </MapContainer>
    </Container>
  );
};

ContactSection.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        post: PropTypes.string,
      })
    ).isRequired,
    address: PropTypes.shape({
      streetNumber: PropTypes.string.isRequired,
      streetName: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      province: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      unitNumber: PropTypes.string,
      unitType: PropTypes.string,
      coordonate: PropTypes.shape({
        lat: PropTypes.string.isRequired,
        lng: PropTypes.string.isRequired,
      }),
    }).isRequired,
    openingHours: PropTypes.shape({
      openingHourLines: PropTypes.arrayOf(),
    }),
    documents: PropTypes.arrayOf(),
  }).isRequired,
};

export default ContactSection;
