// vendors
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Wrapper,
  SlideList,
  SlideContainer,
  Pagination,
  NavigationButton,
  ControlsWrapper,
} from './carousel.styles';
import IconArrow from '../../images/IconArrow';
import PictureSlide from './PictureSlide';

const SLIDE_BEFORE = 2;
const SLIDE_AFTER = 2;

const Carousel = ({ images, ...rest }) => {
  const [activeSlide, setActiveSlide] = useState(SLIDE_BEFORE);
  const [wrapperBounding, setWrapperBounding] = useState(null);
  const [slides, setSlides] = useState([]);
  const animateRef = useRef(false);

  const wrapperRef = useRef(null);

  const handleAnimationEnd = () => {
    let nextSlide = activeSlide;

    if (activeSlide < SLIDE_BEFORE) {
      nextSlide = SLIDE_BEFORE + images.length - 1;
    }

    if (activeSlide > SLIDE_BEFORE + images.length - 1) {
      nextSlide = SLIDE_BEFORE;
    }

    if (nextSlide > -1 && nextSlide < slides.length) {
      setActiveSlide(nextSlide);
    }

    animateRef.current = false;
  };

  const handleResize = () => {
    setWrapperBounding(wrapperRef.current.getBoundingClientRect());
  };

  const handleNavigation = (value) => {
    animateRef.current = true;

    setActiveSlide(activeSlide + value);
  };

  useEffect(() => {
    if (wrapperRef !== null) {
      handleResize();
    }
  }, [wrapperRef]);

  useEffect(() => {
    setSlides([
      ...images.slice(-1 * SLIDE_BEFORE),
      ...images,
      ...images.slice(0, SLIDE_AFTER),
    ]);
  }, [images]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let translateX = 0;

  if (wrapperBounding !== null) {
    for (let i = 0; i < activeSlide; i += 1) {
      translateX -= slides[i].aspectRatio * wrapperBounding.height;
    }

    translateX +=
      (wrapperBounding.width -
        slides[activeSlide].aspectRatio * wrapperBounding.height) /
      2;
  }

  const visualActiveSlide = ((activeSlide - SLIDE_BEFORE) % images.length) + 1;

  return (
    <Container {...rest}>
      <Wrapper ref={wrapperRef}>
        {wrapperBounding && (
          <SlideList
            style={{
              transform: `translateX(${translateX}px)`,
              transitionDuration: !animateRef.current ? '0ms' : '400ms',
            }}
            onTransitionEnd={handleAnimationEnd}
          >
            {slides.map((image, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SlideContainer key={`${image.src}-${index}`}>
                <PictureSlide
                  active={activeSlide === index}
                  img={image}
                  animating={animateRef.current}
                  wrapperRef={wrapperRef}
                  style={{
                    height: wrapperBounding.height,
                    width: `${wrapperBounding.height * image.aspectRatio}px`,
                  }}
                />
              </SlideContainer>
            ))}
          </SlideList>
        )}

        <ControlsWrapper>
          <NavigationButton
            type='button'
            onClick={() => handleNavigation(-1)}
            back
          >
            <IconArrow />
          </NavigationButton>

          <NavigationButton type='button' onClick={() => handleNavigation(1)}>
            <IconArrow />
          </NavigationButton>
        </ControlsWrapper>
      </Wrapper>

      <Pagination>
        {visualActiveSlide} / {images.length}
      </Pagination>
    </Container>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Carousel;
